import React, {useState} from 'react'
import jsonp from 'jsonp'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import styled
 from 'styled-components'
const Resources: React.FC = () => {
    const [email, setEmail] = React.useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const POST_URL = 'https://myshopify.us2.list-manage.com/subscribe/post?u=68e88507ece94451283b77c22&amp;id=71f5f394e5&amp;f_id=001f6de3f0';

  const onSubmit = (e: any) => {
    e.preventDefault();
    jsonp(`${POST_URL}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
      console.log('data', data);
      const { result } = data;
      if (result === 'success') setSubmitSuccess(true);
    });
  };

  const renderForm = () => <>
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Input placeholder="Email" id="email" name="EMAIL" type="email" required onChange={(e) => setEmail(e.target.value)} />
      </FormGroup>
      <Button outline block type="submit">Sign up!</Button>
    </Form>
  </>

  const renderThankyou = () =>
    <h2>Thank you for signing up. Check your email for updates.</h2>



    return (
        <IntroRow className='roboto-mono-cave-evil p-3'>
            <Col  lg={6}>
                <h2 className='mb-3'>Resources</h2>
                <p>
                    <a href="https://store.cave-evil.com/" target='_blank' rel='noopener noreferrer'>
                        Pit of Infinite Shadow: &raquo; Online shop
                    </a>
                </p>
                <br/>
                Cave Evil Newsletter
                <p>
                    <small>Be the first to learn about updates on the reprint and new products from EEE and others
                    </small>
                </p>
                {submitSuccess ? renderThankyou() : renderForm()}

                <br/><br/>
                Follow us
                <div>
                    <ul>
                        <li><a href="https://www.youtube.com/@chthoniccrystalchannel3197" target='_blank' rel="noopener noreferrer">YouTube</a></li>
                        <li><a href="https://twitter.com/caveevil" target='_blank' rel="noopener noreferrer">X</a></li>
                        <li><a href="https://instagram.com/cave_evil" target='_blank' rel="noopener noreferrer">Instagram</a></li>
                        <li><a href="https://www.facebook.com/caveevil/" target='_blank' rel="noopener noreferrer">Facebook</a></li>
                        <li><a href="https://discord.gg/agMaRCeE" target='_blank' rel="noopener noreferrer">Community managed Discord</a></li>
                    </ul>
                </div>
            </Col>
            <Col  lg={6}>Read Reviews / Watch Videos
                <div>
                    <ul>
                        <li><a href="https://mongolcult.com/2020/08/18/into-the-pit-an-interview-with-the-emperors-of-eternal-evil/" target='_blank' rel='noopener noreferrer'>Interview 2020 by Andi Lennon</a></li>
                        <li><a href="https://www.youtube.com/watch?v=5pYOgwZTJEk" target='_blank' rel='noopener noreferrer'>Calendale plays CE</a></li>
                        <li><a href="https://www.youtube.com/watch?v=HnT6VCGgIaw" target='_blank' rel='noopener noreferrer'>Calendale reviews CE</a></li>
                        <li><a href="https://rottenshape.bandcamp.com/album/cave-evil-radio-mix" target='_blank' rel='noopener noreferrer'>CAVE EVIL radio mix</a></li>
                        <li><a href="https://www.youtube.com/watch?v=LqTIsPHEkXw" target='_blank' rel='noopener noreferrer'>CAVE EVIL unboxing</a></li>
                        <li><a href="https://apps.apple.com/us/app/cave-evil-radio/id1571799266?platform=iphone" target='_blank' rel='noopener noreferrer'>CAVE EVIL RADIO - Free iOS app</a></li>
                    </ul>
                </div>
            </Col>
        </IntroRow>)
}

export default Resources

export const IntroRow = styled(Row)`
    color: #999;
    font-size: x-large;
    margin: 2rem;
    padding-bottom: 4rem;
`


/*


<div id="mc_embed_shell">
                    <div id="mc_embed_signup">
                        <form action="https://myshopify.us2.list-manage.com/subscribe/post?u=68e88507ece94451283b77c22&amp;id=71f5f394e5&amp;f_id=00489ce3f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self" >
                            <div id="mc_embed_signup_scroll">
                                <div className="mc-field-group">
                                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" value="" placeholder='Enter your email' />
                                </div>
                                <div id="mce-responses" className="clear">
                                    <div className="response" id="mce-error-response" style={{ display: 'none'}}></div>
                                    <div className="response" id="mce-success-response" style={{ display: 'none'}}></div>
                                </div>
                                <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                    <input onChange={() => console.log('')} type="text" name="b_68e88507ece94451283b77c22_71f5f394e5"  value="" placeholder='Enter your email'/>
                                </div>
                                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
                
*/