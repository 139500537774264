import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Col } from 'reactstrap'

const Header: React.FC = () => {

    const make = (text: string) => isMobileOnly ? <h3>{text}</h3> : <h1>{text}</h1>
    
    return (
        <>
            <Col>
                <a href='/' rel='noopener noreferrer'>
                {make('Cave Evil')}
                </a>
            </Col>
            <Col className='ml-auto text-end'>
                <a href='https://store.cave-evil.com' rel='noopener noreferrer' target='_blank'>
                {make('Store')}
                </a>
            </Col>
            <Col className='text-end'>
                <a href='https://store.cave-evil.com/pages/about' rel='noopener noreferrer' target='_blank'>
                {make('Contact')}
                </a>
            </Col>
        </>
    )
}

export default Header