import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import warcults from '../img/warcults.png'


const Warcults: React.FC = () => {
    return (
        <Container className='mt-9 p-9 border-top pt-5'>
            <Row>
                <Col lg={6}>
                    <a href="https://store.cave-evil.com/collections/board-games/products/cave-evil-warcults-1" target='_blank' rel='noopener noreferrer'><img src={warcults} className='img-fluid' alt='Cave Evil Warcults cover'/></a>
                </Col>
                <Col lg={6}>
                    <WarcultsTitle className='cefext'>
                        <a href="https://store.cave-evil.com/collections/board-games/products/cave-evil-warcults-1" target='_blank' rel='noopener noreferrer'>Cave Evil: Warcults</a>
                        </WarcultsTitle>
                    <WarcultsText className="roboto-mono-cave-evil">
                    <ul>
                        <li><a href="https://boardgamegeek.com/boardgame/186723/cave-evil-warcults/ratings" target='_blank' rel="noopener noreferrer">113 Ratings</a> & 70 Comments</li>
            <li>2&mdash;4 Players</li>
            <li>Playing Time: 180 Min</li>
            <li>Age: 16+</li>
            <li>Designers Mat Brinkman, Jochen Hartmann, Nate Hayden</li>
            <li><a href="https://boardgamegeek.com/boardgame/186723/cave-evil-warcults" target='_blank' rel='noopener noreferrer'>more info and discussion on bgg</a></li>
            <li><a href="https://store.cave-evil.com/collections/board-games/products/cave-evil-warcults-1" target='_blank' rel='noopener noreferrer'>resources: campaign sheet, tutorial links, how to play, FAQ</a></li>
        </ul>
        </WarcultsText>
                </Col>
            </Row>
        </Container>
    )
}

export default Warcults

const WarcultsTitle = styled.h2`
    font-size: 4rem;
    
`

const WarcultsText = styled.div`

color: #999;
font-size: medium;
margin: 2rem;
padding-bottom: 4rem;
`