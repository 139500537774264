import React from 'react'
import { Col, Row } from 'reactstrap'

import reprint from '../img/cave-evil-reprint-image.png'
import cp1 from '../img/cave-evil-cp1.png'
import cp2 from '../img/cave-evil-cp2.png'
import cp3 from '../img/cave-evil-cp3.png'
import statue from '../img/cave-evil-hideous-statue.png'
import purpleLs from '../img/cave-evil-purple-ls.png'
import styled from 'styled-components'

const ImageRow: React.FC = () => {
    return (
        <div style={{ maxWidth: '80%', margin: 'auto'}}>
        <Row className='p-5'>
            <FlexCol lg={4}>
                <a href="https://store.cave-evil.com/products/cave-evil-13th-year-anniversary-edition" target='_blank' rel='noopener noreferrer'><img src={reprint} className="img-fluid" alt="cave evil 13th anniversary edition reprint" /></a>
                <br />
                <span>CAVE EVIL 13th Anniversary edition reprint</span><br /><br />
                <PurpleSmall>Now available!</PurpleSmall>
            </FlexCol>
            <FlexCol lg={4}>
                <a href="https://store.cave-evil.com/collections/board-games/products/cave-evil-chaos-pack-1" target='_blank' rel='noopener noreferrer'><img src={cp1} className="img-fluid" alt="chaos pack 1" /></a>
                <br />
                <span>CHAOS PACK 1</span><br /><br />
                <PurpleSmall>18 new cards, new rules and mini-map</PurpleSmall>
            </FlexCol>
            <FlexCol lg={4}>
                <a href="https://store.cave-evil.com/products/copy-of-cave-evil-chaos-pack-2" target='_blank' rel='noopener noreferrer'><img src={cp2} className="img-fluid" alt="chaos pack 2" /></a>
                <br />
                <span>CHAOS PACK 2</span><br /><br />
                <PurpleSmall>18 new cards, new rules and 'King of the Hell' mini-map</PurpleSmall>
            </FlexCol>
            <FlexCol lg={4}>
                <a href="https://store.cave-evil.com/products/cave-evil-chaos-pack-3" target='_blank' rel='noopener noreferrer'><img src={cp3} className="img-fluid" alt="chaos pack 3" /></a>
                <br />
                <span>CHAOS PACK 3</span><br /><br />
                <PurpleSmall>45 new player created cards! 3 - 11” x 17” Mini Maps! THE PITS and DOUBLE TROUBLE TUNNEL (2 map challenge)</PurpleSmall>
        </FlexCol>
        <FlexCol lg={4}>
            <a href="https://store.cave-evil.com/collections/cave-evil-and-warcults-art" target='_blank' rel='noopener noreferrer'><img src={statue} className="img-fluid" alt="hideous statue by manifester" /></a>
                <br />
                <span>Hideous Statue</span><br /><br />
                <PurpleSmall>Original art from CAVE EVIL and other EEE games</PurpleSmall>
        </FlexCol>
        <FlexCol lg={4}>
            <a href="https://store.cave-evil.com/collections/shirts" target='_blank' rel='noopener noreferrer'><img src={purpleLs} className="img-fluid" alt="cave evil long sleeve shirt" /></a>
                <br />
                <span>Under the Command of the High Hellion! Long sleeve</span><br /><br />
                <PurpleSmall>Shirts and other merch</PurpleSmall>
        
            
        </FlexCol>
        </Row>
        <Row className='align-center'>
       
    </Row>
    </div>
    )
}

export default ImageRow


export const FlexCol = styled(Col)`

text-align: center;
padding-bottom: 4rem;
border: 1px solid black;
margin-bottom: 2rem;
&:hover,
  &:focus {
    border: 1px solid #490ee0;
    box-shadow: 20px 30px 155px rgb(80 34 208);
  }
  &:active {
    border: 1px solid yellow;
  }

`

export const PurpleSmall = styled.small`
    color: #9E00FF;
    font-size: 1rem;
    font-family: monospace;
`
