import React from 'react'
import styled from 'styled-components'
import cc from '../img/cthonic-footer.png'
import { Row } from 'reactstrap'
import { FlexCol } from './ImageRow'

const CthonicCrystal: React.FC = () => {
    return (
        <>
       <Row>
        <FlexCol>
            <NecromancerImage src={cc} />
        </FlexCol>
       </Row> 
       <Row>
        <FlexCol>
            <h1><a href="https://www.youtube.com/@chthoniccrystalchannel3197" target='_blank' rel='noreferrer noopener'>Chthonic Crystal Channel on YouTube</a></h1>
        </FlexCol>
        </Row> 
       <Row>
        <FlexCol>
        <iframe height="315" src="https://www.youtube.com/embed/KlVvcCL__FA?si=emRHZqW2G_6guALB" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </FlexCol>
       </Row>
       </>
    )
}

export default CthonicCrystal

const NecromancerImage = styled.img`
vertical-align: middle;
max-width: 50%;
border: 5px solid #490ee0;



`
