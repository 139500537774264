import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled
 from 'styled-components'
const IntroTextRow: React.FC = () => {
    return (
        <IntroRow className='roboto-mono-cave-evil p-3'>
            <Col  lg={6}>"The living dug out cities for their dead: necropolises deep within the earth. They filled the caverns with bodies and locked them away from the upper air. Eventually the dead forgot the living — if any remain.<br/><br/>But in death, a new life began. The breath of evil from the darkness of complete shadow stirred corrupted flesh. In their middens and pits, the dead awakened, swarming through slime-fluxing tunnels, seeking chthonic power, warring with demons and denizens of the deep. Animated by the black flame, necro-ecologies rose and collapsed in the crypt-universe. Now, cloistered undead lich-wizards have discovered the primordial opening to the shadow source, the doleful horror-hole where the Eternal Evil Emperor watches and waits....</Col>
            <Col lg={6}>The necromancers have dug their Lairs deep into the ground. They must gather precious resources and body parts to assemble an army of minions, while defending their own lairs from the excavations and intrusions of others. In the end, the only route is into the demon path...
Then depletion of your essence leads to spending another eternity in foul sepulchral darkness awaiting the next Awakening of Death."<br/>- The Necromancer's Almanac, 2021
<br/><br/><BigPurple>Dig deeper into the Necropolis as you prepare to conquer all who oppose you.</BigPurple>
<br/><br/>
<Container>
    <Row></Row>
    <Row>
        <ul>
            <li><a href='https://boardgamegeek.com/boardgame/84889/cave-evil/ratings?rated=1' target='_blank' rel='noopener noreferrer'>546 Ratings</a> &amp; <a href='https://boardgamegeek.com/boardgame/84889/cave-evil/ratings?comment=1' target='_blank' rel='noopener noreferrer'>242 Comments</a></li>
            <li>2&mdash;4 Players</li>
            <li>Playing Time: 180 Min</li>
            <li>Age: 16+</li>
            <li>Designers Mat Brinkman, Jochen Hartmann, Nate Hayden</li>
            <li><a href="https://boardgamegeek.com/boardgame/84889/cave-evil" target='_blank' rel='noopener noreferrer'>more data on boardgamegeek</a></li>
            <li><a href="https://boardgamegeek.com/boardgame/186723/cave-evil-warcults" target='_blank' rel='noopener noreferrer'>CAVE EVIL: warcults</a></li>
        </ul>
    </Row>
</Container>
</Col>
    </IntroRow>

    )
}

export default IntroTextRow

export const IntroRow = styled(Row)`
    color: #999;
    font-size: medium;
    margin: 2rem;
    padding-bottom: 4rem;
`

const BigPurple = styled.span`
color: #9E00FF;
font-size: 2rem;
font-family: "FetteTrumpDeutsch";
`