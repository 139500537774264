import React from 'react'
import { Row } from 'reactstrap'
import styled
 from 'styled-components'
const Footer: React.FC = () => {
    return (
        <IntroRow className='roboto-mono-cave-evil p-3 my-5 py-5'>
                &copy; 2024, Emperors of Eternal Evil
        </IntroRow>

    )
}

export default Footer

const IntroRow = styled(Row)`
    color: #999;
    font-size: x-large;
    margin: 2rem;
    padding-bottom: 5rem;
    box-shadow: 14px 20px 20px 20px #331f84
    background-color: black;
`
