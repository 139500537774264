import React from 'react'
import styled from 'styled-components'
import { Container, Row } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import Header from './components/Header'
import IntroTextRow from './components/IntroTextRow'
import Warcults from './components/Warcults'
import ImageRow from './components/ImageRow'

import splash from './img/splash_img.png'

import Resources from './components/Resources'
import CthonicCrystal from './components/CthonicCrystal'
import Footer from './components/Footer'
import { isMobileOnly } from 'react-device-detect'

function App() {
  return (
      <DarkContainer fluid>
        <HeaderRow className='p-3 position-fixed w-100'>
          <Header />
        </HeaderRow>
        <Row className={isMobileOnly ? 'pt-5': ''} ><img src={splash} className="img-fluid" alt="cave evil box" /></Row>
        <IntroTextRow />
        <ImageRow />
        <Warcults />
        <Resources/>
        <CthonicCrystal />
        <Footer/>

      </DarkContainer>
  )
}

export default App;

const HeaderRow = styled(Row)`
  background-color: 'black';
  min-height: 2rem;
  backdrop-filter: blur(10px);
`

const DarkContainer = styled(Container)`
  background-color: #000;
  color: #fff;
  font-family: 'FetteTrumpDeutsch';
  font-size: 1.5rem;
`